<template>
  <div class="section">
    <div class="bg-user-info"></div>
    <div class="container">
      <div id="user-info">
        <div class="row">
        <div class="col-lg-12 my-auto">
          <form @submit.prevent="submitUser">
            <div class="form-container" >
              <p class="text-center">Masukkan datamu terlebih dahulu agar dapat mengetahui hasil test kamu</p>
              <label for="name">Nama</label>
              <input type="text" class="form-control mb-3" id="name" v-model="body.user.name" placeholder="Masukkan nama">
              <label for="email">Email</label>
              <input type="email" class="form-control mb-3" id="email" v-model="body.user.email" placeholder="Masukkan e-mail">
              <label for="phone">No HP/WA</label>
              <input type="tel" class="form-control mb-3" id="phone" v-model="body.user.phone"  placeholder="Masukkan No HP/WA">
              <label for="city">Kota</label>
              <select class="form-control mb-3" id="city" v-model="body.user.city" >
                <option v-for="(city,index) in cities" :key="index">{{ cities[index] }}</option>
              </select>
            </div>
            <div class="col-lg-12 justify-content-center text-center">
              <button class="btn btn-outline-violet">
                Lihat hasil tes
                <fa-icon icon="arrow-right" class="ml-2"/>
              </button>
            </div>
          </form>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Vue from "vue"

export default {
  name: "user-info",
  props: ['answer'],
  data() {
    return {
      cities: ["Banda Aceh", "Langsa", "Lhokseumawe", "Sabang", "Subulussalam", "Denpasar", "Pangkalpinang", "Cilegon", "Serang", "Tangerang Selatan", "Tangerang", "Bengkulu", "Gorontalo", "Kota Administrasi Jakarta Barat", "Kota Administrasi Jakarta Pusat", "Kota Administrasi Jakarta Selatan", "Kota Administrasi Jakarta Timur", "Kota Administrasi Jakarta Utara", "Sungai Penuh", "Jambi", "Bandung", "Bekasi", "Bogor", "Cimahi", "Cirebon", "Depok", "Sukabumi", "Tasikmalaya", "Banjar", "Magelang", "Pekalongan", "Salatiga", "Semarang", "Surakarta", "Tegal", "Batu", "Blitar", "Kediri", "Madiun", "Malang", "Mojokerto", "Pasuruan", "Probolinggo", "Surabaya", "Pontianak", "Singkawang", "Banjarbaru", "Banjarmasin", "Palangkaraya", "Balikpapan", "Bontang", "Samarinda", "Tarakan", "Batam", "Tanjungpinang", "Bandar Lampung", "Metro", "Ternate", "Tidore Kepulauan", "Ambon", "Tual", "Bima", "Mataram", "Kupang", "Sorong", "Jayapura", "Dumai", "Pekanbaru", "Makassar", "Palopo", "Parepare", "Palu", "Bau-Bau", "Kendari", "Bitung", "Kotamobagu", "Manado", "Tomohon", "Bukittinggi", "Padang", "Padangpanjang", "Pariaman", "Payakumbuh", "Sawahlunto", "Solok", "Lubuklinggau", "Pagaralam", "Palembang", "Prabumulih", "Binjai", "Medan", "Padang Sidempuan", "Pematangsiantar", "Sibolga", "Tanjungbalai", "Tebingtinggi", "Yogyakarta"],
      body: {
        user: {
          name: '',
          email: '',
          phone: '',
          city: ''
        }
      },
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
      result: '' 
    }
  },
  methods: {
    async submitUser() {

      Vue.set(this.body, 'answers', this.answerData);
      this.result = await this.getResult();
      this.$store.state.answerData = [];
      this.$router.push({name: this.result});
    
    },
    async getResult() {
      const url = new URL(
          "https://app.semesta.io/api/bb549c966f24fd99176623745abc503e/led-free-test"
      );
      let headers = {
        "Content-Type": "application/json",
        "Accept": "application/json",
      };

      let body = JSON.stringify(this.body)
  
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: body
      });

      const json = await response.json();
      return json.data.title;
    }
  },
  computed:{
    answerData() {
      return this.$store.state.answerData;
    }
  }
};
</script>

<style scoped>

</style>
